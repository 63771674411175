import { FormDropdown, FormInput, FormTextArea } from "components/FormInputs";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Button, Grid, Table } from "semantic-ui-react"
import { formatUSD, transformDropdownOptions } from "../utils";
import Loading from "components/Loading";

const yearArray = Array.from({ length: 3 }, (_, i) => {
    const year = new Date().getFullYear() + i;
    return { key: year, text: year.toString(), value: year };
});

const KpiDetails = ({ register, errors, getValues, setValue, trigger, reset, control, dropDownData, isEditable, isViewMode }: any) => {
    const [showKPITable, setShowKPITable] = useState<boolean>(false);

    const { fields, append, update } = useFieldArray({
        name: "projectKPIDetails",
        control
    });

    const kpiDetailsRows = (item: any, index: number) => {
        function handleKpiTypeChange(value: any, index: number) {
            const currentValues = getValues(`projectKPIDetails.${index}`);
            setValue(`projectKPIDetails.${index}`, {
                ...currentValues,
                kpiType: value,
                kpiMetric: undefined // Reset kpiMetric when kpiType changes
            });
            trigger(`projectKPIDetails.${index}.kpiMetric`); // Re-validate kpiMetric field
        }

        function getKpiMetricsOptions(kpiType: string) {
            switch (kpiType) {
                case "1": // Commercial
                    return allOptions?.paKpiMetricOption?.filter((metric: any) => metric.key === '1');
                case "2": // Marketing/Thought Leadership
                    return allOptions?.paKpiMetricOption?.filter((metric: any) => ['2', '3', '4', '5'].includes(metric.key));
                case "3": // Internal Enablement
                    return allOptions?.paKpiMetricOption?.filter((metric: any) => ['6', '7'].includes(metric.key));
                case "4": // Other
                    return allOptions?.paKpiMetricOption?.filter((metric: any) => metric.key === '8');
                default:
                    return [];
            }
        }

        return (
            <Table.Row key={index}>
                <Table.Cell width={1}>
                    <FormDropdown
                        name={`projectKPIDetails.${index}.year`}
                        options={yearArray || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        defaultValue={getValues(`projectKPIDetails.${index}.year`)}
                    />
                </Table.Cell>
                <Table.Cell width={2}>
                    <FormDropdown
                        name={`projectKPIDetails.${index}.kpiType`}
                        options={allOptions.paKpiTypeOption || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        defaultValue={String(item.kpiType)}
                        onChange={(e: any, { value }: any) => handleKpiTypeChange(value, index)}
                    />
                </Table.Cell>
                <Table.Cell width={2}>
                    <FormDropdown
                        name={`projectKPIDetails.${index}.kpiMetric`}
                        options={getKpiMetricsOptions(String(getValues(`projectKPIDetails.${index}.kpiType`))) || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        defaultValue={String(item.kpiMetric)}
                    />
                </Table.Cell>
                <Table.Cell width={3}>
                    <div className="kpi-measure-fields">
                        <FormDropdown
                            style={{ marginBottom: 0 }}
                            name={`projectKPIDetails.${index}.kpiMeasure`}
                            options={allOptions.paKpiMeasureOption || []}
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                            trigger={trigger}
                            defaultValue={String(item.kpiMeasure)}
                        />
                        <FormInput
                            name={`projectKPIDetails.${index}.measureData`}
                            register={register}
                            errors={errors}
                            style={{ marginLeft: '10px' }}
                            label=""
                            type="text"
                            onChange={(e: any) => {
                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                e.target.value = formatUSD(value);
                            }}
                            detectChange={(e: any) => { }}
                        />
                    </div>
                </Table.Cell>
                <Table.Cell width={2}>
                    <FormTextArea
                        label=''
                        name={`projectKPIDetails.${index}.kpiDetails`}
                        register={register}
                        errors={errors}
                        validation={{ required: false }}
                        rows={3}
                    />
                </Table.Cell>
                <Table.Cell width={2}>
                    <FormDropdown
                        name={`projectKPIDetails.${index}.kpiProgress`}
                        options={allOptions.paKpiProgressOption || []}
                        register={register}
                        getValues={getValues}
                        setValue={setValue}
                        trigger={trigger}
                        defaultValue={String(item.kpiProgress)}
                    />
                </Table.Cell>
                <Table.Cell width={3}>
                    <FormTextArea
                        label=''
                        name={`projectKPIDetails.${index}.progressDetails`}
                        register={register}
                        errors={errors}
                        validation={{ required: false }}
                        rows={3}
                        readOnly={isViewMode}
                    />
                </Table.Cell>
            </Table.Row>
        )
    }

    const addKPIDetails = () => {
        append({ projectDetailsId: '', measureData: '', year: null, kpiType: '', kpiMetric: '', kpiMeasure: '', kpiProgress: '', kpiDetails: '', progressDetails: '' });
    }

    const allOptions = useMemo(() => {
        return {
            paKpiMeasureOption: transformDropdownOptions(dropDownData?.paKpiMeasure),
            paKpiMetricOption: transformDropdownOptions(dropDownData?.paKpiMetric),
            paKpiTypeOption: transformDropdownOptions(dropDownData?.paKpiType),
            paKpiProgressOption: transformDropdownOptions(dropDownData?.paKpiProgress),
        }
    }, [dropDownData]);

    useEffect(() => {
        if (dropDownData && fields.length === 0) {
            append({
                projectDetailsId: '',
                measureData: '',
                year: yearArray[0].value,
                kpiType: allOptions.paKpiTypeOption[0].value,
                kpiMetric: allOptions.paKpiMetricOption[0].value,
                kpiMeasure: allOptions.paKpiMeasureOption[0].value,
                kpiProgress: allOptions.paKpiProgressOption[0].value,
                kpiDetails: '',
                progressDetails: ''
            });
        }
    }, [reset, allOptions]);

    useEffect(() => {
        setTimeout(() => {
            setShowKPITable(true)
        }, 1000);
    }, []);

    return (
        <div className="border-3 border-primary-color">
            <h5 className="text-background-primary text-white-color padding-1 margin-0">KPI Details</h5>
            <div className="padding-4 margin-b-4">
                <Table size="small">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            <Table.HeaderCell>KPI Type</Table.HeaderCell>
                            <Table.HeaderCell>Metric</Table.HeaderCell>
                            <Table.HeaderCell>Measure ($/#/%)</Table.HeaderCell>
                            <Table.HeaderCell>KPI Details</Table.HeaderCell>
                            <Table.HeaderCell>Progress</Table.HeaderCell>
                            <Table.HeaderCell>Progress Details</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            showKPITable ? fields?.map(kpiDetailsRows) : <Loading />
                        }
                        <Table.Row>
                            <Table.Cell width={3}>
                                <Button type="button" size="mini" onClick={addKPIDetails}>Add KPI Details</Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Grid>
                    <Grid.Row columns={2}>
                        <div className="padding-4 margin-b-4">

                            <FormTextArea
                                label='Share additional details on KPI progress and other project developments'
                                name={`additonalKPIDetails`}
                                register={register}
                                errors={errors}
                                validation={{ required: false }}
                                rows={3}
                            />
                        </div>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    )
}

export default KpiDetails